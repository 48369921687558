<template>
	<div class="flex-container">
		<div>
			<el-image :src="global.getImage('contact.png')" style="width: 800px;"></el-image>
			<div>地址：长沙市岳麓区绿地时代广场写字楼17楼</div>
			<div>官网：<a href="http://www.hnfreefly.com">www.hnfreefly.com</a></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ContactUs',
		data() {
			return {}
		},
		components: {

		}
	}
</script>

<style lang="scss" scoped="true">
	.flex-container{
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px;
		div{
			color:$primary-font-color-deep;
			font-weight: bold;
		}
	}
</style>
